import BannerImg from "../../../../content/assets/identity-verification-providers.svg"
import SecurityImg from "../../../../content/assets/security.svg"
import BadgesImg from "../../../../content/assets/gdpr-and-ccpa-readiness-badges.png"

const identityVerificationProvidersPageData = {
  hero_banner: {
    heading: "Identity Verification Providers",
    background_colour: "#53254f",
    text_colour: "#fff",
    subheading: `<p>With the help of our consortium of data partners, WINR Data is providing multi-national blue chip companies with data driven identity solutions via a single access point, reducing the need for acquisition, management and compliance resources.</p>`,
    include_image_slider: true,
    slider_images: [
      {
        url: BannerImg,
        alt: "",
      },
    ],
  },
  image_text_split: {
    background_colour: "#fdfdfd",
    intro_enabled: true,
    intro_title: "Identity Verification Applications",
    intro_text: `<p style="margin-bottom: 0;">Each client will have their own definitions and requirements around permitted use but in general the data is used for Identity Verification (IDV), Anti-Money Laundering (AML), Know Your Customer (KYC) and Mercantile Services. For example:</p>`,
    content_type: "checkmark_items",
    items: [
      {
        heading: "Fake Account Creation",
        description: `Ecommerce companies wish to streamline the customer onboarding experience by identifying potential bad actors during the account creation process by independently verifying the customer's name, postal address, email and phone number before doing business with them.`,
      },
      {
        heading: "Age Verification",
        description: `Industries such as Fintech, liquor and gaming need to verify a customer's age before supplying a service.`,
      },
      {
        heading: "Mercantile Services and Asset Reunification",
        description: `Data may be used for receivables management, asset reunification including tracing and reuniting investors with unclaimed assets.`,
      },
    ],
    additional_text: `<p style="margin-top: 20px; margin-bottom: 0;" class="note-info"><strong><em>Note:</em></strong> <span class="note-content"><em>Unless agreed with WINR Data and its data partners and as a separate licence or agreement, the raw data <strong>may not be used for direct mail, email or telemarketing purposes</strong>.</em></span></p>`,
    image: {
      url: SecurityImg,
      alt: "Identity verification",
    },
    remove_horizontal_margin: false,
    image_alignment: "left",
    reverse_order_mobile: true,
  },
  image_text_split_2: {
    background_colour: "#000000",
    text_colour: "#ffffff",
    intro_enabled: true,
    intro_title: "Data Attributes",
    intro_text: `<p>WINR Data is committed to bringing together the best data sources for global electronic identity verification, and to ensuring our sources comply with all applicable data privacy laws and regulations. Typical commercial deals require the following fields (where available):</p>
      <ul class="content-list two-col-list row">
        <li class="col md-6">First Name</li>
        <li class="col md-6">Last Name</li>
        <li class="col md-6">Street Address</li>
        <li class="col md-6">Suburb</li>
        <li class="col md-6">State</li>
        <li class="col md-6">Postcode</li>
        <li class="col md-6">Mobile Number</li>
        <li class="col md-6">Email</li>
        <li class="col md-6">DOB (dd/mm/ccyy)</li>
        <li class="col md-6">IP Address (If Available)</li>
        <li class="col md-6">Recency/Date Created (dd/mm/ccyy)</li>
        <li class="col md-6">Id</li>
      </ul> 
      <p>We receive file updates as standard on a quarterly basis but can be upgraded where required and if commercially viable.</p>
      <p style="margin-bottom: 0;">WINR Data continues to expand our consortium of data partners, looking for the best in-country sources of information to integrate directly into our Data as a Service platform to help meet this growing market demand.</p>     
    `,
    content_type: "simple_text",
    remove_horizontal_margin: false,
  },
  image_text_split_3: {
    background_colour: "#fdfdfd",
    intro_enabled: true,
    intro_title: "Comfort in Compliance",
    intro_text: ``,
    text: `<p>WINR Data's key focus it to deliver data that has passed the robust compliance checks in line with the philosophy of Transparency, Compliance and Consent.</p>
      <p>Working with our Compliance Advisory Consultants, we work through a privacy questionnaire/due diligence check list for each data partner in a standard format and present them to you for review. If extra detail is required, we work with each of the data partners to provide the relevant information.</p>
      <p>If any partner fails to reach the required standards of the client then that file is simply excluded from the programme until the compliance is remedied and the relevant standards are met.</p>
      <p>Our information security systems have been implemented to deliver an effective privacy and security program consistent with global data protection frameworks such as GDPR, CCPA and the Australian Privacy Act.</p>
      <p style="margin-bottom: 0;">All our solutions are built and hosted on AWS to inherit the most comprehensive compliance controls available globally. AWS supports more security standards and compliance certifications than any other offering, including PCI-DSS, HIPAA/HITECH, FedRAMP, GDPR, FIPS 140-2, and NIST 800-171, helping customers satisfy compliance requirements for virtually every regulatory agency around the globe.</p>`,
    content_type: "simple_text",
    image: {
      url: BadgesImg,
      alt: "GDPR and CCPA Ready",
    },
    remove_horizontal_margin: false,
    image_alignment: "right",
    reverse_order_mobile: false,
  },
}

export { identityVerificationProvidersPageData }
