import * as React from "react"
import { identityVerificationProvidersPageData } from "components/Data/Solutions/IdentityVerificationProvidersPageData"
import { createGlobalStyle } from "styled-components"
import { NavLink, NavExtLink } from "components/NavMenuDesktop"
import { LogoWrap } from "components/HeaderLogo"
import { UnderlineElement } from "components/Ui/Underline"
import SeoComponent from "components/SeoComponent"
import Layout from "components/Layout"
import HeroBanner from "components/HeroBanner"
import ImageTextSplit from "components/Page/ImageTextSplit"

const SolutionsPageTheme = createGlobalStyle`
  .headroom--unfixed {
    ${NavLink},
    ${NavExtLink} {
      transition: color 0.25s ease;
      
      &::after {
        background-color: #ffffff;
      }

      &:hover {
        color: #ffffff;
      }
    }

    ${UnderlineElement} {
      background-color: #ffffff;
    }    

    header nav.nav-desktop {
      color: #ffffff;

      a {
        color: #ffffff;

        &:focus {
          color: #ffffff;
        }
      }
    }
    .hamburger-inner, .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: #ffffff;
    }

    ${LogoWrap} {
      svg path {
        &.winr-data-logo_svg__c {
          fill: #ffffff;
        }      
      }
    }    
  }
  
  .headroom--pinned {
    ${LogoWrap} {
      svg path {
        &.winr-data-logo_svg__c {
          fill: #000000;
        }      
      }
    }
  }  

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #ffffff;
  }
`

const IdentityVerificationProvidersPage = () => {
  const page = identityVerificationProvidersPageData

  return (
    <Layout pageName="identity-verification-providers">
      <SeoComponent title="Identity Verification Providers" description="Providing multi-national blue chip companies with data driven identity solutions via a single access point, reducing the need for acquisition, management and compliance resources." />
      <SolutionsPageTheme />
      {page && page.hero_banner && (
        <HeroBanner
          fields={page.hero_banner}
          pageName="identity-verification-providers"
          preHeading="Solutions"
        />
      )}

      {page && page.image_text_split && (
        <ImageTextSplit fields={page.image_text_split} />
      )}

      {page && page.image_text_split_2 && (
        <ImageTextSplit fields={page.image_text_split_2} />
      )}

      {page && page.image_text_split_3 && (
        <ImageTextSplit fields={page.image_text_split_3} />
      )}

      {page && page.image_text_split_4 && (
        <ImageTextSplit fields={page.image_text_split_4} />
      )}
    </Layout>
  )
}

export default IdentityVerificationProvidersPage
